<template lang="pug">
main
  navigation
  section#newFuture
    div(class="content")
      //- title
      div(class="content-title")
        h3.category 六書堂創新科技VS敦煌經典教材 打造兒童英文新未來
        .divider
        p(class="source") 民眾日報
        p(class="source-date") 2023年9月12日
      div(class="content-newsImg")
        img(class="newsImg" src="@/assets/collaboration-with-caves-education/news-img1.svg")
        p ▲（圖／業者提供）《圖說》合作簽約儀式由敦煌集團語言學習事業處協理洪村裕（左二）與六書堂執行長吳政哲（右二）共同主持。
      div(class="content-text")
        | 【民眾網諸葛志一臺中報導】面對全球化時代，政府積極推動「2030雙語國家政策」，如何幫助學童突破缺乏實際應用、學習動機低落等困境，六書堂數位學習集團旗下兒童英文品牌 WUWOW JUNIOR 與敦煌教育集團，宣布合作攜手打造O2O教育新思維！雙方期待各自發揮所長，整合資源，提供更豐富、完整、有趣的英文學習方式，讓台灣學子不管在線上或實體都能夠快樂學習，建立堅實英語基礎。
        br
        br
        | 合作簽約儀式11日舉行，由敦煌集團語言學習事業處協理洪村裕與六書堂執行長吳政哲共同主持。六書堂取得敦煌教育集團21st Century系列教材授權，內容與外師團隊共同編撰，特別結合108課綱核心素養，培養品德教育、家庭教育等內容，並在課後附上練習音檔及仿照劍橋兒童英檢題型，協助孩子們穩紮穩打學英文！而WUWOW JUNIOR為一線兒童線上英文平台，以AI智慧配對、遊戲化教學、即時互動等創新技術，讓孩子在快樂中自然學英語，目前已累計開課近15,000堂，獲得家長和孩子一致好評。
        br
        br
        | 雙方將利用線上與線下活動相互搭配，強化學習體驗的成效，把線上學到的知識透過實體活動實際應用，把學習的「知道」強化到「做到」。例如：萬聖節期間，線上學習以萬聖節為主題的英文課程，結合線下實體美術手作課程，製作南瓜燈。讓學習過程更有趣好玩，並且達成英文複習、實際應用的成效！
      div(class="content-newsImg")
        img(class="newsImg" src="@/assets/collaboration-with-caves-education/news-img2.svg")
        p ▲簽約儀式由敦煌集團語言學習事業處協理洪村裕，與六書堂CEO吳政哲簽署，未來雙方將利用線上與線下活動相互搭配，強化學習體驗的成效，（圖／業者提供）
      div(class="content-text")
        | 六書堂執行長吳政哲表示，敦煌教育集團深耕外語教育70年，很關注台灣的英文教育發展，對於台灣學生適合的教學內容、教學方法有深入研究，此次合作授權CAVES KIDS教材內容更是專門為「台灣學生」設計，更勝直接引進國外出版社的英文學習教材。WUWOW JUNIOR，將在教學中導入背單字、簽到、獎勵等各式系統服務，將學習轉為打怪破關的遊戲化形式，輔助學習。兩大教育集團的合作，可以向快樂學習更加靠攏，為英文教育發展注入新活水。
        br
        br
        | 敦煌集團協理洪村裕表示，認同WUWOW JUNIOR 教學理念，線上學習與實體學習有互補性，且逐漸成為趨勢，因此非常樂見WUWOW JUNIOR 選擇 21st Century 系列教材作為線上課程的內容，希望藉線上線下整合，搶佔先機。另外，線上課程隨時隨地都可以學習、並與世界各地不同國籍的師資接觸，加上教育部也以「班班有網路，生生用平板」為口號，朝向數位學習方向邁進。
      div(class="content-newsImg")
        img(class="newsImg" src="@/assets/collaboration-with-caves-education/news-img3.svg")
        p ▲《圖說》六書堂攜手敦煌集團，共同合作打造兒童英文新未來。
      div(class="news-source")
        p 新聞資料來源
        div(class="news-source-url")
          | 1. NOWnews：
          a(href="https://www.nownews.com/news/6258820" target="_blank") 六書堂聯手敦煌教育集團 為兒童創造英文教學新天地
        div(class="news-source-url")
          | 2. UDN聯合報：
          a(href="https://udn.com/news/story/6885/7433006" target="_blank") 六書堂取得敦煌線上授權 整合資源讓英文變好玩了
        div(class="news-source-url")
          | 3. 民眾日報（Yahoo）：
          a(href="https://tw.news.yahoo.com/%E5%85%AD%E6%9B%B8%E5%A0%82%E5%89%B5%E6%96%B0%E7%A7%91%E6%8A%80vs%E6%95%A6%E7%85%8C%E7%B6%93%E5%85%B8%E6%95%99%E6%9D%90-%E6%89%93%E9%80%A0%E5%85%92%E7%AB%A5%E8%8B%B1%E6%96%87%E6%96%B0%E6%9C%AA%E4%BE%86-005806214.html" target="_blank") 六書堂創新科技VS敦煌經典教材 打造兒童英文新未來
  lioshutanFooter
</template>

<script>
// component
import navigation from '@/views/home/navigation/index.vue';
import lioshutanFooter from '@/components/footer/index.vue';

// css
import 'bootstrap/dist/css/bootstrap.css';

export default {
  name: 'CollaborationWithCavesEducation',
  components: { navigation, lioshutanFooter },
};

</script>

<style scope>

main{
  background-color:  #FFFFFF;
}

/* mobile first */
section#newFuture {
  width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  padding-top: 17px;
  padding-bottom: 40px;
  width:70%;
  letter-spacing: 1px;
  --maim-color: #88C2E8;
  --text-black: #000;
  margin-top: 4rem;
}

.content-title > .category {
  font-size: 38px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: left;
}

.content-title >.divider {
    margin: 10px 0 14px;
    background-color: var(--maim-color);
    height: 4px;
}

.source {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: #4F5D62;
}

.source-date {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #4F5D62;
}

.content-newsImg {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
}

.newsImg {
  width: 100%;
  max-width: 688px;
}

.content-newsImg > p {
  max-width: 688px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 3rem;
}

.content-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 3rem;
}

.news-source {
  width: 70%;
  height: Hug (233px);
  padding: 20px;
  border-radius: 10px;
  gap: 20px;
  background-color: #F5F5F5;
  margin-left: auto;
  margin-right: auto;
}

.news-source-url {
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  text-align: left;
  color: #4F5D62;
}

.news-source-url > p {
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 1px;
}

.news-source-url > a {
  text-decoration: underline;
  color: #1287D5;
}

@media screen and (max-width: 1024px) {
  .content, .news-source {
    width:90%;
  }
  .content-title > .category {
    font-size: 24px;
  }
  .content-newsImg {
    width:100%;
  }
}
</style>
